import { Slider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { countPopulationChange, getPopulationAgeRanges, sumPopulationPerYear } from '../../common/functions';
import { PopulationChangeChart, PopulationChangeType } from '../../components/charts/ComposedCharts';
import { AgeGroupChart } from '../../components/charts/LineCharts';
import { CohortByAreaByYearType } from '../../types';
import { useGetAdjustedDataForecastForForecastQuery } from '../apis/apiSlice';
import { useGetAreasQuery } from '../apis/areaSlice';
import { selectCurrentForecast } from '../apis/forecastApi';
import { AGE_GROUPS, PopulationGroupSizes } from '../municipality/ChangeBarChart';
import { AreaSelection } from './AreaSelection';
import { getAreaHierarchy } from './queryViewFunctions';

import * as dfd from 'danfojs';

const UNKNOWN_AREA_ID = '999999'
const SMALL_AREA_IDX = 0
const MAJOR_AREA_IDX = 1

const jsonToDataFrame = (json: CohortByAreaByYearType): dfd.DataFrame => {

    const areas = Object.entries(json).flatMap(
        ([year, areaData]) => (
            Object.entries(areaData).flatMap(
                ([area, cohort]) => [{ key: `${year}_${area}`, year: year, area: area, ...cohort }])
        ))

    return new dfd.DataFrame(areas, { index: Object.values(areas).map(x => x.key) })
}

const dataFrameToJSON = (df: dfd.DataFrame): CohortByAreaByYearType => {

    const data = dfd.toJSON(df) as any[]

    return data.reduce((prev, curr) => {
        const { year, key: _, area, ...rest } = curr
        return {
            ...prev,
            [year]: {
                ...prev[year],
                [area]: {
                    ...prev[year]?.[area],
                    ...rest,
                }
            }
        }
    }, {} as any) as any
}

const QueryView: React.FC = () => {

    const { t } = useTranslation()

    const { id: forecastId, fromYear, toYear } = useAppSelector(selectCurrentForecast)!

    const [populationChange, setPopulationChange] = useState<PopulationChangeType | undefined>(undefined)
    const [currentYear, setCurrentYear] = useState<number>(fromYear)
    const [ages, setAges] = useState<{ from: number, to: number }>({ from: 0, to: 99 })

    const [selectedAreas, setSelectedAreas] = useState<string[]>([])

    const { data: forecast } = useGetAdjustedDataForecastForForecastQuery({ forecastId: forecastId! })
    const { data: areas } = useGetAreasQuery()

    const [dataframe, setDataframe] = useState<dfd.DataFrame | undefined>(undefined)
    const [yearArray, setYearArray] = useState<number[]>([])

    const [perYearFilteredData, setPerYearFilteredData] = useState<CohortByAreaByYearType | undefined>(undefined)
    const [perAreaFilteredData, setPerAreaFilteredData] = useState<CohortByAreaByYearType | undefined>(undefined)

    const [ ageGroupAttributes, setAgeGroupAttributes ] = useState<{ ageGroups: PopulationGroupSizes, maxSize: number, minYear: number, maxYear: number }>({ ageGroups: [], maxSize: 0, minYear: 0, maxYear: 0 })

    useEffect(() => {
        
        if (!!!perYearFilteredData) return

        setAgeGroupAttributes(getPopulationAgeRanges({
            ...perYearFilteredData,
        }, AGE_GROUPS))

    }, [perYearFilteredData, currentYear])

    useEffect(() => {
        if (areas) {
            
            const areaIds = Object.keys(areas[SMALL_AREA_IDX])
                .concat(
                    Object.keys(areas[MAJOR_AREA_IDX])
                    .filter(area => !area.endsWith(UNKNOWN_AREA_ID))
                )

            setSelectedAreas(areaIds)
        }
    }, [areas])

    useEffect(() => {
        if (forecast) {
            const df = jsonToDataFrame(forecast.adjusted_projections[0])
            setDataframe(df)
            setYearArray(Array.from({ length: toYear - fromYear + 1 }, (_, i) => fromYear + i))
        }
    }, [ forecast, fromYear, toYear ])

    useEffect(() => {

        if (!(currentYear && ages && areas && dataframe)) return


        const smallAreas = selectedAreas.filter(area => area.length > 4)
        const areasPerYear = yearArray.flatMap(year => smallAreas.map(area => `${year}_${area}`))
        const areasPerCurrentYear = areasPerYear.filter(area => area.startsWith(`${currentYear}_`))

        const yearlyFilteredDf = dataframe.loc({
            rows: areasPerCurrentYear,
            columns: [
                'year',
                'area',
                ...Array.from(
                    { length: ages.to - ages.from + 1 },
                    (_, i) => `f${ages.from + i}`
                ),
                ...Array.from(
                    { length: ages.to - ages.from + 1 },
                    (_, i) => `m${ages.from + i}`
                )
            ]
        })

        const areaFilteredDf = dataframe.loc({
            rows: areasPerYear,
            columns: [
                'year',
                'area',
                ...Array.from(
                    { length: ages.to - ages.from + 1 },
                    (_, i) => `f${ages.from + i}`
                ),
                ...Array.from(
                    { length: ages.to - ages.from + 1 },
                    (_, i) => `m${ages.from + i}`
                )
            ]
        })

        const filtered = dataFrameToJSON(yearlyFilteredDf) as CohortByAreaByYearType
        setPerYearFilteredData(filtered)
        const areaFiltered = dataFrameToJSON(areaFilteredDf) as CohortByAreaByYearType
        setPerAreaFilteredData(areaFiltered)

    }, [ currentYear, ages, areas, dataframe, selectedAreas, yearArray ])

    useEffect(() => {

        if (!perAreaFilteredData) return

        const populationSums = sumPopulationPerYear(perAreaFilteredData)
        const populationChange = countPopulationChange(populationSums)

        setPopulationChange(populationChange! as any)

    }, [perAreaFilteredData])

    const [ tempYear, setTempYear ] = useState<number>(fromYear)
    const [ tempAges, setTempAges ] = useState<{ from: number, to: number }>({ from: 0, to: 99 })

    return areas ? (
        <Grid2 container width={'100%'} height={'80%'}>
            <Grid2 xs={3}>
                <Typography variant='h3' style={{ fontSize: '1rem', fontWeight: 700 }}>{t('queryView.areas')}</Typography>
                <AreaSelection
                    areas={getAreaHierarchy(areas![1], areas![0])}
                    selectedAreas={selectedAreas}
                    setSelectedAreas={setSelectedAreas}
                />
                {
                    // @ts-ignore
                    <Slider
                        sx={{ paddingTop: '2.5rem' }}
                        title={t('general.ages')}
                        min={0}
                        max={99}
                        value={[tempAges.from, tempAges.to]}
                        onChange={(e, [from, to]: number[]) => setTempAges({ from, to })}
                        onChangeCommitted={(e, [from, to]: number[]) => setAges({ from, to })}
                        valueLabelDisplay='on'
                        marks={[
                            { value: 0, label: '0' },
                            { value: 99, label: '99' }
                        ]}
                    />
                }
            </Grid2>
            <Grid2 container xs={9}>
                <Grid2 xs={12} container>
                    <Grid2 xs={6}>
                        <PopulationChangeChart data={populationChange!} />
                    </Grid2>
                    <Grid2 xs={6}>
                        {ageGroupAttributes.ageGroups.length && <AgeGroupChart ageGroups={ageGroupAttributes.ageGroups} maxSize={ageGroupAttributes.maxSize} minYear={ageGroupAttributes.minYear} />}
                        <Slider
                            title='test'
                            min={fromYear}
                            max={toYear}
                            value={tempYear}
                            onChange={(_, value) => setTempYear(Number(value))}
                            onChangeCommitted={(_, value) => setCurrentYear(Number(value))}
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
    ) : null
};

export default QueryView;