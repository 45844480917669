import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../authConfig';
import { PopulationChangeType } from "../components/charts/ComposedCharts";
import { PopulationGroupPercentages, PopulationGroupSizes } from "../features/municipality/ChangeBarChart";
import { CohortByAreaByYearType, CohortType } from "../types";

export const sumCohorts = (data: CohortType): number => Object.values(data).reduce((a, b) => a + b, 0)

const filterAgeGroup = (data: CohortType, from: number, to: number): CohortType =>
    Object.entries(data).filter(([key, _]) => Number(key.substring(1)) >= from && Number(key.substring(1)) <= to).reduce((a, [key, value]) => ({ ...a, [key]: value }), {} as CohortType)

export const sumAgeGroup = (data: CohortType, from: number, to: number): number => {
    const total = sumCohorts(filterAgeGroup(data, from, to))
    return total
}

export const sumAgeGroupPerSex = (data: CohortType, from: number, to: number, sex: 'f' | 'm'): number => {
    const cohortBySex = Object.entries(data).filter(([key, value]) => key.startsWith(sex)).reduce((a, [key, value]) => ({ ...a, [key]: value }), {} as CohortType)
    const total = sumCohorts(filterAgeGroup(cohortBySex, from, to))
    return total
}

export type PopulationSums = {
    year: number,
    total: number,
}[]

const sumCohort = (data: CohortType): number => Object.values(data).reduce((a, b) => a + b, 0)
export const sumPopulationPerYear = (data: CohortByAreaByYearType): PopulationSums =>
    Object.entries(data).map(([year, area]) => ({
        year: Number(year),
        total: Object.values(area).reduce((prevSum, currArea) => prevSum + sumCohort(currArea), 0)
    }))

export const countPopulationChange = (data: PopulationSums = []): PopulationChangeType => {

    const populationChange: PopulationChangeType = []

    for (let i = 0; i < data.length; i++) {
        if (i === 0) {
            populationChange.push({
                year: data[i].year,
                changePercentage: 0,
                total: data[i].total,
            })
        } else {
            populationChange.push({
                year: data[i].year,
                changePercentage: ((data[i].total - data[i - 1].total) / data[i - 1].total) * 100,
                total: data[i].total,
            })
        }
    }

    return populationChange
}

type AgeGroup = {
    from: number,
    to: number,
}

const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

export const getPopulationPercentageRanges = (data: CohortByAreaByYearType = {}, ageGroups: AgeGroup[]): PopulationGroupPercentages => {

    const percentages = Object.entries(data).map(([year, area], idx) => {

        const cohorts = Object.values(area)[0]
        const previousCohorts = Object.values(data[idx === 0 ? year : Number(year) - 1])[0]
        const total = sumCohorts(cohorts)
        const previousTotal = sumCohorts(previousCohorts)

        const percentages = ageGroups.map((ageGroup) => {

            const current = sumAgeGroup(cohorts, ageGroup.from, ageGroup.to) / total * 100
            const previous = sumAgeGroup(previousCohorts, ageGroup.from, ageGroup.to) / previousTotal * 100

            return {
                from: ageGroup.from,
                to: ageGroup.to,
                percentage: current - previous,
            }
        })

        return {
            year,
            total,
            percentages,
        }
    })

    return percentages.reduce((prev, curr) => ({

        ...prev,
        ...curr.percentages.map((item) => {

            const key = item.from + '-' + item.to

            return {
                [key]: {
                    label: key,
                    change: [
                        round(Math.min(item.percentage, prev[key] ? prev[key].change[0] : Infinity)),
                        round(Math.max(item.percentage, prev[key] ? prev[key].change[1] : -Infinity))
                    ]
                }
            }
        }).reduce((a, b) => ({ ...a, ...b }), {} as any)

    }), {} as any)
}

export const getPopulationAgeRanges = (
    data: CohortByAreaByYearType = {}, ageGroups: AgeGroup[]):
    { ageGroups: PopulationGroupSizes, maxSize: number, minYear: number, maxYear: number } => {

    const [ minYear, maxYear ] = [ Math.min(...Object.keys(data).map(Number)), Math.max(...Object.keys(data).map(Number)) ]
    const reversedAgeGroups = ageGroups.sort((a, b) => b.from - a.from)

    const ranges =
        Object.entries(data)
            .filter(( [year ]) => Number(year) === minYear || Number(year) === maxYear)
            .map(([year, area], idx) => {

                const cohorts = {
                    [year]: {
                        ...reversedAgeGroups.map((ageGroup) => {

                            const ageGroupString = `${ageGroup.from}-${ageGroup.to}`

                            return {
                                male: {
                                    sex: 'male',
                                    label: ageGroupString,
                                    size: sumAgeGroupPerSex(Object.values(area)[0], ageGroup.from, ageGroup.to, 'm'),
                                },
                                female: {
                                    sex: 'female',
                                    label: ageGroupString,
                                    size: -sumAgeGroupPerSex(Object.values(area)[0], ageGroup.from, ageGroup.to, 'f'),
                                }
                            }
                        })
                        .reduce((prev, curr) => ({
                            ...prev,
                            ...curr,
                            male: [ ...(prev.male || []), curr.male ],
                            female: [ ...(prev.female || []), curr.female ],
                        }), {} as any)
                    }
                }
        
        return cohorts
    }) as PopulationGroupSizes

    const allSizes =
        ranges
            .flatMap((years) => Object.values(years))
            .flatMap((sexes) => Object.values(sexes).map(Object.values))
            .flatMap((what) => what)
            .flatMap((group) => Math.abs(group.size))

    return {
        ageGroups: ranges,
        maxSize: Math.ceil(Math.max(...allSizes) / 1000) * 1000,
        minYear,
        maxYear,
    } as any
}

export const acquireToken = async () => {

    const instance = new PublicClientApplication(msalConfig);
    const activeAccount = instance.getActiveAccount()
    const accounts = instance.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
    }

    const tokenRequest = {
        scopes: ['api://8564cbea-4f0d-4b08-9269-b03b4e330094/access_as_user'],
        account: activeAccount || accounts[0]
    };

    try {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        return await instance.acquireTokenSilent(tokenRequest)
    } catch (e) {
        return await instance.acquireTokenPopup(tokenRequest)
    }
}
