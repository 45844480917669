import Box from '@mui/material/Box'
import { RichTreeView, TreeViewBaseItem, useTreeViewApiRef } from '@mui/x-tree-view'
import { useRef } from 'react'

const getItemDescendantsIds = (item: TreeViewBaseItem) => {

  const ids: string[] = []
  
  item.children?.forEach((child) => {
    ids.push(child.id)
    ids.push(...getItemDescendantsIds(child))
  })

  return ids
}

type AreaSelectionPropsType = {
  areas: TreeViewBaseItem[],
  selectedAreas: string[],
  setSelectedAreas: (areas: string[]) => void
}
export const AreaSelection = ({ areas, selectedAreas, setSelectedAreas }: AreaSelectionPropsType) => {

  const toggledItemRef = useRef<{ [itemId: string]: boolean }>({})
  const apiRef = useTreeViewApiRef()

  const handleSelectedItemsChange = (
    event: React.SyntheticEvent,
    newSelectedItems: string[],
  ) => {

    setSelectedAreas(newSelectedItems)

    // Select / unselect the children of the toggled item
    const itemsToSelect: string[] = []
    const itemsToUnSelect: { [itemId: string]: boolean } = {}

    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = (apiRef.current! as any).getItem(itemId)
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item))
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true
        })
      }
    })

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    )

    setSelectedAreas(newSelectedItemsWithChildren)

    toggledItemRef.current = {}
  }

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    toggledItemRef.current[itemId] = isSelected
  }

  return (
    <Box sx={{ minHeight: 352, minWidth: 290 }}>
      <RichTreeView
        multiSelect
        checkboxSelection
        apiRef={apiRef}
        items={areas}
        selectedItems={selectedAreas}
        onSelectedItemsChange={handleSelectedItemsChange}
        onItemSelectionToggle={handleItemSelectionToggle}
      />
    </Box>
  )
}